body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.registration-modal {
  text-align: left; }
  .registration-modal h3 {
    text-align: center; }
  .registration-modal .social {
    margin: 30px 0; }
    .registration-modal .social__label {
      flex-grow: 1;
      flex-shrink: 0;
      text-align: center;
      font-weight: 800;
      color: #666666;
      margin-top: 10px; }
    .registration-modal .social__buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      position: relative;
      width: 266px; }
      .registration-modal .social__buttons a {
        text-decoration: none;
        margin: 8px 0; }
    .registration-modal .social__button {
      display: flex;
      align-items: center;
      padding: 0;
      width: 300px;
      height: 37px;
      color: #fff;
      border: 0;
      border-radius: 3px;
      cursor: pointer; }
      .registration-modal .social__button-media-svg {
        width: 37px;
        height: 37px;
        border: 0;
        margin-right: 20px; }
      .registration-modal .social__button--google {
        background: #4285f4; }
      .registration-modal .social__button--linkedin {
        background: #1577b5; }
      .registration-modal .social__button--facebook {
        background: #4067b4; }
  .registration-modal__button {
    width: 100%;
    background-color: #008B87;
    color: #fff;
    font-size: 15px;
    align-self: baseline;
    display: inline-block;
    border-radius: 7px;
    letter-spacing: 1px;
    padding: 10px 17px;
    white-space: nowrap;
    font-family: "brandon", sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    outline: none;
    margin: 0 0 10px;
    user-select: none;
    border: none;
    cursor: pointer; }
    .registration-modal__button:hover {
      background-color: #FFF100;
      color: #008B87; }
  @media (min-width: 768px) {
    .registration-modal .modal__body {
      width: 100%; } }
  .registration-modal .hodden {
    position: fixed;
    top: -100px; }
  .registration-modal #customBtn {
    width: 134px;
    height: 39px;
    display: flex;
    align-items: center;
    background-color: #1a73e8;
    border-radius: 3px;
    position: absolute;
    top: 8px;
    left: 0;
    z-index: -8; }
  .registration-modal #customBtn:hover {
    cursor: pointer; }
  .registration-modal span.label {
    font-family: serif;
    font-weight: normal; }
  .registration-modal span.icon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAMAAABFjsb+AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAV9QTFRF////+tjV9J+Y8oyD7WZa8YJ5/evp7V1R61BD6ks+6kM16kk8++Hg7mpf8Yd97mhd6kU37V5T8Htx8YR8+9rX8Yd+97Wv++Lg/Ojn/Orp7nJn+9jV/vz8/vHL96xM61E661RI/vb1/Obk/Nlz+rsR96wv7l9A9Kaf/M1I+7wF+rwM+LQz/fHwss362OX8+78Q+8o+U5D1qMf5/MxCQoX0oML5+8k28Mc3/NNc3MEua7VbwOTK2+j8ocL5s876/emuvsJLW7FVOKlWVbZv9Pr29fj/ap/25+/+/vvzdL52NqhUNKhTdsSL1e3c6fbthrjfRof0eqn3/P3/zenUPKxbO6tZULRreMWNjc6fl9OnR6t0XqPNSor01eT8+vz6u+LFP61dQq5fPahoYKyrttD6+vz+9/z4xebOXLl1R7Bjw+TR9vn+4vLmueHDcMKHmtOp3fDi5/Tq2+/g2O7e8PjyY5mFzQAAAP1JREFUeJxjYAADRiZmFhZWJkYGOGBjZufg5OLi5mBnZoMK8fACBSCAkw+qil8AyBMUEhYEkiIQMVGgkKCYuISkuJiUtAxYSFZOnktBWhHEVFSCCDEoq6iqqcswoAANTS1tHRBDFwr0GBj0NTUNwJKGUGDEwKCpqWkMFjOBAlMGBjNNTXN0MQtNSytrkJgNENgCxewYGOwdHJ2cXSD2uboBxdwZGDw8vby9fHxBQn7+ASYmgUFAVnCIt3doWHhEZLhzVLRJTCxINi4eKOgdmpAY6u2VlJySCjYlLT3DGwoys7KhXonLyc3LCPHOzMsvSEN4MK0wp6i4oLAEzAEAGzwwidABZLUAAAAASUVORK5CYII=) transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 31px;
    height: 31px;
    margin: 4px;
    margin-right: 10px;
    border-radius: 2px;
    background-color: white; }
  .registration-modal span.buttonText {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 24px; }
