.set-password-modal .status {
  transition: all 0.2s ease-in-out;
  pointer-events: none; }
  .set-password-modal .status--closed {
    opacity: 0; }
  .set-password-modal .status--opened {
    opacity: 1; }

.set-password-modal svg {
  width: 20px;
  height: 17px;
  display: inline-block;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 6px; }
