body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.modal.rodo-modal .modal__window .modal__body {
  width: 100%;
  padding: 30px; }

.modal.rodo-modal .modal__window .title {
  margin-bottom: 20px; }

.modal.rodo-modal .modal__window .modal__terms {
  font-size: 13px;
  padding: 10px;
  max-height: 240px;
  overflow-y: auto;
  border: 1px solid #e2e2e2;
  text-align: left;
  margin-bottom: 20px; }

.modal.rodo-modal .modal__window .modal__copy {
  padding-bottom: 20px;
  text-align: left; }

.modal.rodo-modal .modal__window .button {
  background: #008B87;
  color: #fff; }

@media (min-width: 768px) {
  .modal.rodo-modal .modal__window .modal__body {
    width: 600px; } }
