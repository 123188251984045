.modal--startCoViewing {
  z-index: 41; }
  .modal--startCoViewing .modal-secondary-content {
    padding: 10px 20px; }
  .modal--startCoViewing .hash-title {
    font-size: 22px;
    padding-top: 16px; }
  .modal--startCoViewing input {
    border: 0 !important; }
  .modal--startCoViewing .hash-value {
    font-size: 42px;
    color: #2E2E2E;
    font-weight: 500;
    padding-bottom: 18px; }
  .modal--startCoViewing .links-row {
    display: flex;
    align-items: flex-end;
    padding: 10px 0;
    flex-wrap: wrap; }
    .modal--startCoViewing .links-row .links-detail {
      flex-grow: 1;
      flex-shrink: 0;
      text-align: left; }
    .modal--startCoViewing .links-row .links-label {
      color: #828282; }
    .modal--startCoViewing .links-row .links-textfield input {
      box-shadow: none; }
      .modal--startCoViewing .links-row .links-textfield input:focus {
        box-shadow: none; }
    .modal--startCoViewing .links-row .links-cta {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: auto;
      text-align: right; }
  .modal--startCoViewing .modal-loader {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 auto; }
    .modal--startCoViewing .modal-loader .spinner {
      position: absolute;
      border-left: 1.1em solid #2E2E2E; }
  .modal--startCoViewing .modal-title, .modal--startCoViewing .hash-title, .modal--startCoViewing .hash-value, .modal--startCoViewing .links-detail > div, .modal--startCoViewing .links-cta span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500; }
