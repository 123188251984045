body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.presentations {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1 0 0%;
  box-sizing: border-box;
  width: 100%; }
  .presentations__presentation {
    margin: 15px 30px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }
    .presentations__presentation::after, .presentations__presentation::before {
      content: '';
      clear: both;
      display: block; }
  .presentations__presentation-container {
    flex-basis: auto;
    box-sizing: border-box;
    width: 100%; }
  .presentations__link {
    position: relative;
    height: 170px;
    width: 100%;
    display: block;
    background: #fff; }
  .presentations__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .presentations__background .presentations__overlay {
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: black;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out; }
  .presentations__background:hover .presentations__overlay {
    opacity: 0.7; }
  .presentations__details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    text-align: center; }
    .presentations__details::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.7) 100%); }
  .presentations__name {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: left;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    bottom: 12px;
    padding: 0 6px; }
  .presentations__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F2F2F2; }
  .presentations__author {
    display: flex;
    align-items: center; }
  .presentations__avatar {
    display: block;
    height: 35px;
    width: 35px; }
  .presentations__author-name {
    font-size: 13px;
    width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 1px;
    padding-left: 20px;
    text-transform: capitalize; }
  .presentations__stats {
    display: flex;
    align-items: center;
    color: #676767;
    padding-right: 20px; }
    .presentations__stats img {
      width: 22px;
      height: 15px;
      margin-right: 4px;
      vertical-align: baseline; }
  .presentations__views {
    font-size: 13px; }
  @media (min-width: 768px) {
    .presentations__presentation {
      margin: 15px 15px; }
    .presentations__presentation-container {
      width: 33%; } }
  @media (min-width: 1280px) {
    .presentations__presentation-container {
      width: 25%; } }

.play {
  width: 45%;
  height: 45%; }
  .play path:nth-child(1) {
    fill: #fff; }
  .play path:nth-child(2) {
    fill: #FCEE1D; }
