.modal--startCoViewing .modal-secondary-content {
  padding: 10px 20px; }

.modal--startCoViewing .hash-title {
  font-size: 22px;
  padding-top: 16px; }

.modal--startCoViewing input {
  border: 0 !important; }

.modal--startCoViewing .hash-value {
  font-size: 42px;
  color: #2E2E2E;
  font-weight: 500;
  padding-bottom: 18px; }

.modal--startCoViewing .links-row {
  display: flex;
  align-items: flex-end;
  padding: 10px 0;
  flex-wrap: wrap; }
  .modal--startCoViewing .links-row .links-detail {
    flex-grow: 1;
    flex-shrink: 0;
    text-align: left; }
  .modal--startCoViewing .links-row .links-label {
    color: #828282; }
  .modal--startCoViewing .links-row .links-textfield input {
    box-shadow: none; }
    .modal--startCoViewing .links-row .links-textfield input:focus {
      box-shadow: none; }
  .modal--startCoViewing .links-row .links-cta {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: auto;
    text-align: right; }

.modal--startCoViewing .modal-loader {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto; }
  .modal--startCoViewing .modal-loader .spinner {
    position: absolute;
    border-left: 1.1em solid #2E2E2E; }

.modal--startCoViewing .modal-title, .modal--startCoViewing .hash-title, .modal--startCoViewing .hash-value, .modal--startCoViewing .links-detail > div, .modal--startCoViewing .links-cta span {
  font-family: 'Roboto', sans-serif;
  font-weight: 500; }

.vr-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: flex;
  align-items: center;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7); }

.container {
  width: 450px;
  margin: 0 auto;
  background-color: white; }

@media (max-width: 600px) {
  .container {
    height: 100vh;
    width: 100vw; }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%; } }

.modal-title {
  background: #2E2E2E;
  padding: 22px;
  font-size: 20px;
  line-height: 23px;
  color: #fff;
  text-align: left;
  margin-top: -1px; }

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center; }

.vr-img {
  width: auto;
  height: 30vh;
  margin-top: 16px;
  margin-bottom: 30px; }

.cta-button {
  background: #FFF100;
  border-radius: 30px;
  padding: 14px 0;
  width: 220px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 25px; }

.stay-in-browser {
  text-transform: uppercase;
  color: grey;
  font-size: 13px;
  margin-bottom: 16px;
  cursor: pointer; }
