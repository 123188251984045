body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.text-align-center {
  text-align: center; }

.vc-container {
  display: table;
  position: relative;
  height: 100%;
  width: 100%; }
  .vc-container .vc {
    vertical-align: middle;
    height: 100%;
    width: 100%;
    display: table-cell; }
  .vc-container .vc.vc-bottom {
    vertical-align: bottom; }

body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.main-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  pointer-events: none;
  transition: background-color 0.5s ease-in-out; }
  .main-header a {
    pointer-events: all; }
  .main-header__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 23px 18px; }
    @media (min-width: 768px) {
      .main-header__navbar {
        padding: 28px 42px; } }
    @media (min-width: 970px) {
      .main-header__navbar {
        padding: 28px 58px; } }
    .main-header__navbar.is-active {
      margin: 0; }
      .main-header__navbar.is-active .main-header__menu .main-header__profile {
        display: none !important; }
  .main-header__hamburger {
    width: 20px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    pointer-events: all; }
    @media (min-width: 768px) {
      .main-header__hamburger {
        width: 30px;
        height: 30px; } }
    @media (min-width: 1280px) {
      .main-header__hamburger {
        display: none; } }
    .main-header__hamburger:focus {
      outline: none; }
    .main-header__hamburger span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #FFF100;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out; }
    .main-header__hamburger span:nth-child(1) {
      top: 0px; }
    .main-header__hamburger span:nth-child(2) {
      top: 8px; }
      @media (min-width: 768px) {
        .main-header__hamburger span:nth-child(2) {
          top: 11px; } }
    .main-header__hamburger span:nth-child(3) {
      top: 16px; }
      @media (min-width: 768px) {
        .main-header__hamburger span:nth-child(3) {
          top: 22px; } }
    .main-header__hamburger--active span:nth-child(1) {
      top: 10px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
      opacity: 0; }
    .main-header__hamburger--active span:nth-child(2) {
      opacity: 0;
      left: -60px; }
    .main-header__hamburger--active span:nth-child(3) {
      top: 10px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
      opacity: 0; }
  .main-header__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 38px;
    width: 72px; }
    @media (min-width: 1280px) {
      .main-header__logo {
        position: static;
        left: 0;
        transform: translateX(0);
        width: 60px;
        height: 30px; } }
    .main-header__logo svg {
      fill: #ffffff;
      width: 40px;
      height: 18px; }
      @media (min-width: 768px) {
        .main-header__logo svg {
          width: 76px;
          height: 34px; } }
      @media (min-width: 1280px) {
        .main-header__logo svg {
          width: 60px;
          height: 27px; } }
    .main-header__logo .logo {
      width: 100%;
      height: 100%; }
  .main-header__menu {
    display: flex;
    align-items: center;
    flex-flow: row-reverse nowrap;
    justify-content: space-between; }
    @media (min-width: 970px) {
      .main-header__menu {
        width: 100%; } }
    .main-header__menu.is-active .main-header__container {
      display: block;
      position: fixed;
      height: 100vh;
      width: 100%;
      z-index: 200;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8); }
  .main-header__links-section {
    display: none;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 1280px) {
      .main-header__links-section {
        display: block;
        width: 66%;
        margin: 0 auto; } }
    @media (min-width: 1280px) {
      .main-header__links-section {
        width: 67%; } }
  .main-header__action-buttons {
    pointer-events: all;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 30px; }
    .main-header__action-buttons svg {
      display: block;
      width: 36px;
      height: 36px; }
    .main-header__action-buttons .action-buttons__button {
      display: block !important;
      min-width: 0 !important;
      border-radius: 50% !important; }
  .main-header__links {
    display: flex;
    flex-wrap: wrap; }
  .main-header__item:hover > ul {
    max-height: 1000px;
    -webkit-transform: perspective(400) rotate3d(0, 0, 0, 0); }
  .main-header__link {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    display: block;
    padding: 10px 15px;
    text-transform: capitalize;
    letter-spacing: .4px; }
    .main-header__link.isSelected {
      color: #FFF100; }
    .main-header__link:hover {
      color: #FFF100; }
    .main-header__link--external:focus {
      color: #fff; }
    .main-header__link--external:hover {
      color: #FFF100; }
    @media (min-width: 970px) {
      .main-header__link {
        padding: 10px 14px; } }
    .main-header__link--account {
      display: flex;
      align-items: center;
      padding: 0 25px 0 0; }
      .main-header__link--account__avatar {
        width: 38px;
        height: 38px;
        border-radius: 50%; }
      .main-header__link--account__username {
        display: none;
        margin-left: 10px;
        font-size: 17px;
        max-width: 190px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        @media (min-width: 970px) {
          .main-header__link--account__username {
            display: inline-block; } }
  .main-header__login-button {
    box-sizing: border-box;
    align-self: baseline;
    display: inline-block;
    width: auto;
    border-radius: 7px;
    white-space: nowrap;
    font-family: "brandon", sans-serif;
    font-weight: 500;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    padding: 5px 17px;
    margin: 0 0 10px 10px;
    letter-spacing: 2px; }
    .main-header__login-button:hover {
      background-color: #FFF100;
      color: #006783; }
    @media (max-width: 1279px) {
      .main-header__login-button {
        display: block;
        font-size: 18px;
        padding: 6px 14px; } }
  .main-header__signup-button.button {
    margin: 0; }
    @media (max-width: 1279px) {
      .main-header__signup-button.button {
        display: block;
        font-size: 18px;
        padding: 6px 14px; } }
  @media (max-width: 1279px) {
    .main-header__login-button, .main-header__signup-button.button {
      display: none; } }
  .main-header__login-section {
    display: flex; }
    .main-header__login-section .spinner {
      margin: 0;
      font-size: 2px; }
    @media (min-width: 1280px) {
      .main-header__login-section {
        width: 240px; } }
  .main-header .caret {
    position: relative;
    cursor: pointer; }
    .main-header .caret:hover::after {
      display: block; }
    .main-header .caret::after {
      content: '';
      display: none;
      position: absolute;
      top: 16px;
      right: 4px;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #ffffff; }
    .main-header .caret--large::after {
      display: block;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 8px solid #ffffff; }
  .main-header .dropdown {
    position: absolute;
    top: 34px;
    left: 15px;
    min-width: 160px;
    max-height: 0;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    -webkit-transform: perspective(400) rotate3d(1, 0, 0, -90deg);
    -webkit-transform-origin: 50% 0;
    -webkit-transition: 350ms;
    -moz-transition: 350ms;
    -o-transition: 350ms;
    transition: 350ms; }
    .main-header .dropdown--big {
      top: 38px;
      right: 0; }
    .main-header .dropdown__link {
      display: flex;
      align-items: center;
      padding: 15px;
      margin: 0;
      color: #666666; }
      .main-header .dropdown__link:hover {
        color: #ffffff; }
  .main-header .header__container {
    max-width: 1670px;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between; }
  .main-header .header__logo {
    width: 150px;
    padding: 27px 20px;
    transition: all .2s ease-in-out; }
    .main-header .header__logo svg {
      display: block;
      fill: #ff0;
      height: 46px;
      width: 100px; }
    .main-header .header__logo .logo {
      width: 160px;
      height: 60px; }
  .main-header .header__actions {
    flex-grow: 1;
    text-align: right;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .main-header .header__actions .header__button {
      min-width: 0 !important;
      border-radius: 50% !important; }
      .main-header .header__actions .header__button svg {
        width: 36px;
        height: 36px; }
    .main-header .header__actions a {
      padding: 5px 15px;
      color: #fff;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 1px; }
      .main-header .header__actions a:hover {
        color: #ffff41; }
      .main-header .header__actions a.isSelected {
        color: #ffff41; }
  .main-header__profile {
    color: #fff;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0; }
    .main-header__profile .main-header__email {
      display: none;
      padding: 0 16px;
      white-space: nowrap;
      min-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis; }
    .main-header__profile img {
      border-radius: 50%;
      height: 40px;
      width: 40px; }
    @media (min-width: 768px) {
      .main-header__profile {
        width: 240px; }
        .main-header__profile .main-header__email {
          display: block; } }
  .main-header .sign-buttons {
    padding: 36px 0; }
    .main-header .sign-buttons .sign-buttons__register, .main-header .sign-buttons .sign-buttons__login {
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 13px;
      line-height: 20px;
      margin: 5px 15px; }
    .main-header .sign-buttons .sign-buttons__register {
      border: 2px solid #ffff41;
      color: #ffff41;
      border-radius: 20px;
      padding: 10px 20px;
      transition: all .2s ease-in-out; }
      .main-header .sign-buttons .sign-buttons__register:hover {
        color: #000;
        background: #ffff41; }
    .main-header .sign-buttons .sign-buttons__login {
      line-height: 28px;
      border: none;
      margin: 0;
      padding: 0;
      border-radius: 0;
      color: #fff; }
      .main-header .sign-buttons .sign-buttons__login:hover {
        border-bottom: 1px solid #fff; }
      .main-header .sign-buttons .sign-buttons__login svg {
        margin-top: 3px;
        width: 8px;
        height: 12px;
        margin-right: 10px;
        fill: #fff; }
  .main-header .header__menu-trigger {
    display: none;
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 23px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background 0.2s;
    float: right;
    z-index: 300;
    position: absolute;
    top: 20px;
    right: 30px;
    pointer-events: all; }
    .main-header .header__menu-trigger:focus {
      outline: none; }
    .main-header .header__menu-trigger span {
      display: block;
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      height: 3px;
      background: #FFF100; }
    .main-header .header__menu-trigger span::before,
    .main-header .header__menu-trigger span::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #FFF100;
      content: ""; }
    .main-header .header__menu-trigger span::before {
      top: -10px; }
    .main-header .header__menu-trigger span::after {
      bottom: -10px; }
    .main-header .header__menu-trigger span {
      transition: background 0s 0.2s; }
    .main-header .header__menu-trigger span::before, .main-header .header__menu-trigger span::after {
      transition-duration: 0.2s, 0.2s;
      transition-delay: 0.2s, 0s; }
    .main-header .header__menu-trigger span::before {
      transition-property: top, all; }
    .main-header .header__menu-trigger span::after {
      transition-property: bottom, all; }
    .main-header .header__menu-trigger.is-active span {
      background: none; }
    .main-header .header__menu-trigger.is-active span::before {
      top: 0;
      transform: rotate(45deg); }
    .main-header .header__menu-trigger.is-active span::after {
      bottom: 0;
      transform: rotate(-45deg); }
    .main-header .header__menu-trigger.is-active span::before, .main-header .header__menu-trigger.is-active span::after {
      transition-delay: 0s, 0.2s; }
    .main-header .header__menu-trigger span {
      display: none;
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      height: 3px;
      background: #ffff41;
      transition: background 0s 0.2s; }
  .main-header .header__menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    padding-top: 70px; }
    .main-header .header__menu .sign-buttons, .main-header .header__menu .header__actions {
      display: block;
      width: 27%;
      margin: 0 auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .main-header .header__menu .sign-buttons__register {
      margin: 0;
      width: 100%;
      text-align: center; }
    .main-header .header__menu .sign-buttons__login {
      margin-top: 10px;
      border: 2px solid #fff;
      border-radius: 20px;
      margin-top: 10px;
      border: 2px solid #fff;
      border-radius: 20px;
      width: 100%;
      text-align: center;
      padding: 6px 20px;
      transition: all .2s ease-in-out; }
      .main-header .header__menu .sign-buttons__login:hover {
        background: #fff;
        color: #000; }
        .main-header .header__menu .sign-buttons__login:hover path {
          fill: #000; }
    .main-header .header__menu .header__actions {
      margin-top: 30px; }
  .main-header.main-header--transparent {
    background: transparent; }
  .main-header.main-header--gray {
    height: 115px;
    line-height: 45px;
    background-color: #27282c; }
  .main-header.main-header--dark {
    background-color: rgba(0, 0, 0, 0.7); }
  .main-header.main-header--black {
    height: 115px;
    line-height: 45px;
    background-color: #000; }
  .main-header.main-header--hidden {
    display: none; }
  .main-header.main-header--minimal {
    height: 96px;
    border: none; }
    .main-header.main-header--minimal .main-header__navbar {
      max-width: none; }
      @media (min-width: 1280px) {
        .main-header.main-header--minimal .main-header__navbar .main-header__hamburger {
          display: block; } }
    .main-header.main-header--minimal .main-header__logo {
      display: none; }
    .main-header.main-header--minimal .main-header__profile {
      border: none; }
  .main-header.main-header--minimal.player {
    padding-right: 0 !important;
    z-index: 40; }
    .main-header.main-header--minimal.player .main-header__navbar {
      max-width: none;
      padding: 28px 20px; }
      .main-header.main-header--minimal.player .main-header__navbar .main-header__menu .main-header__profile {
        display: flex;
        justify-content: flex-end; }
  .main-header.main-header--lagardere {
    background: #fff9ff;
    border-bottom: 1px solid #dbdce0;
    height: 100px; }
    .main-header.main-header--lagardere .main-header__hamburger {
      display: none; }
    .main-header.main-header--lagardere .main-header__navbar {
      max-width: none;
      padding: 0; }
    .main-header.main-header--lagardere .header__menu-trigger {
      display: none; }
    .main-header.main-header--lagardere .main-header__container {
      max-width: none; }
    .main-header.main-header--lagardere .main-header__logo {
      width: 360px;
      flex-shrink: 0;
      border-right: 1px solid #dbdce0;
      height: 100px;
      padding: 27px 100px;
      position: static;
      transform: none; }
    .main-header.main-header--lagardere .main-header__menu {
      height: 100px;
      justify-content: flex-start; }
    .main-header.main-header--lagardere .main-header__action-buttons {
      display: none; }
    .main-header.main-header--lagardere .main-header__profile {
      padding: 0 30px;
      border-left: 1px solid #dbdce0; }
      .main-header.main-header--lagardere .main-header__profile a {
        color: #1779ba; }
    .main-header.main-header--lagardere .main-header__email {
      color: #1779ba; }
    .main-header.main-header--lagardere .main-header__button {
      display: none !important; }
    .main-header.main-header--lagardere.main-header--minimal {
      background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
      border-bottom: none; }
      .main-header.main-header--lagardere.main-header--minimal .main-header__logo {
        display: none; }
      .main-header.main-header--lagardere.main-header--minimal .main-header__profile {
        border: none; }
  .main-header.main-header--blur {
    filter: blur(2px); }
  @media only screen and (min-width: 1px) and (max-width: 90em) {
    .main-header .header__actions {
      padding: 6px; }
      .main-header .header__actions a {
        padding: 6px; } }
  @media only screen and (min-width: 1px) and (max-width: 64em) {
    .main-header .header__logo {
      padding: 10px 25px; }
    .main-header .header__menu--open {
      display: block; }
    .main-header .header__menu-trigger {
      display: block; }
      .main-header .header__menu-trigger span {
        display: block; }
    .main-header .header__actions {
      display: none; }
    .main-header .header__profile {
      display: none; }
    .main-header.main-header--lagardere .main-header__action-buttons {
      display: block; } }
  @media only screen and (max-width: 48em) {
    .main-header .header__menu .header__actions, .main-header .header__menu .sign-buttons {
      width: 66%; } }

.main-header__popover {
  top: 50px !important; }
  .main-header__popover .link {
    padding: 11px 32px;
    color: #666666 !important; }
  .main-header__popover .popover__menuItem a {
    display: block;
    height: 100%;
    padding: 0px 16px;
    color: #666666 !important; }

.main-header.main-header--minimal__navbar {
  max-width: none !important; }

.spinner-container {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.spinner-fullsize,
.spinner-fullsize:after {
  text-indent: 0;
  margin-top: 44vh;
  width: 6vw;
  height: 6vw; }

.spinner-fullsize {
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  border-top: 0.5vh solid rgba(255, 255, 255, 0);
  border-right: 0.5vh solid rgba(255, 255, 255, 0);
  border-bottom: 0.5vh solid rgba(255, 255, 255, 0);
  border-left: .5vh solid gray; }
