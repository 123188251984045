body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.presentation-dashboard .slider {
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 10px;
  height: 2px;
  width: 100px; }
  .presentation-dashboard .slider .rc-slider-rail {
    background-color: black;
    height: 2px; }
  .presentation-dashboard .slider .rc-slider-track {
    background-color: black;
    height: 2px; }
  .presentation-dashboard .slider .rc-slider-handle {
    border: solid 2px #323232;
    border-radius: 0;
    background-color: whitesmoke;
    width: 7px;
    height: 20px;
    margin-top: -9px;
    transform: none; }

.presentation-dashboard .slider.white .rc-slider-rail, .presentation-dashboard .slider.white .rc-slider-track, .presentation-dashboard .slider.white .rc-slider-handle {
  background-color: white; }

.presentation-dashboard .slider.white .rc-slider-handle {
  border: none;
  width: 5px; }

.presentation-dashboard .imageIcon {
  width: 30px;
  height: 30px;
  cursor: pointer; }

.presentation-dashboard .imageIcon.white {
  fill: white; }

.presentation-dashboard .imageIcon.disabled {
  fill: #b9b9b9;
  cursor: not-allowed; }

.presentation-dashboard a {
  color: #4D4D4D; }

.presentation-dashboard__wrapper {
  position: fixed;
  max-height: calc(100% - 100px);
  width: calc(100vw - 25px);
  background: rgba(255, 255, 255, 0.94);
  color: #323232;
  z-index: 100;
  top: auto !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
  pointer-events: all;
  display: flex;
  flex-direction: column; }
  .presentation-dashboard__wrapper .dashbord_control-icon {
    fill: #4f4f4f; }
  .presentation-dashboard__wrapper .presentation-dashboard__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 22px;
    box-sizing: border-box;
    flex-shrink: 0; }
  .presentation-dashboard__wrapper .presentation-dashboard__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-items: center;
    align-items: center;
    height: 30px;
    cursor: pointer; }
    .presentation-dashboard__wrapper .presentation-dashboard__title .title-image-container {
      display: flex; }
    .presentation-dashboard__wrapper .presentation-dashboard__title .title-text-container {
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .presentation-dashboard__wrapper .presentation-dashboard__title .title__text {
      font-size: 18px;
      line-height: 16px;
      font-weight: 300;
      vertical-align: top;
      color: #666666; }
    .presentation-dashboard__wrapper .presentation-dashboard__title .title__text.white {
      color: white; }
    .presentation-dashboard__wrapper .presentation-dashboard__title .title__separator {
      width: 1px;
      height: 27px;
      display: inline-block;
      background: #e0e0e0;
      margin: 0 20px; }
    .presentation-dashboard__wrapper .presentation-dashboard__title .title__separator.white {
      background: white; }
    .presentation-dashboard__wrapper .presentation-dashboard__title img {
      height: 27px;
      width: auto;
      vertical-align: baseline; }
  .presentation-dashboard__wrapper .presentation-dashboard__controls {
    text-align: right;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: -6px;
    color: black; }
    .presentation-dashboard__wrapper .presentation-dashboard__controls .flex-container {
      flex: 0 1 auto;
      padding: 3px;
      margin-right: 20px; }
    .presentation-dashboard__wrapper .presentation-dashboard__controls .slider-container {
      display: flex;
      flex-direction: row; }
    .presentation-dashboard__wrapper .presentation-dashboard__controls .slider-icons {
      color: black;
      margin: 0; }
    .presentation-dashboard__wrapper .presentation-dashboard__controls .slider-icons.white {
      color: white; }
    .presentation-dashboard__wrapper .presentation-dashboard__controls .controls-container {
      flex: 0 1 auto;
      flex-wrap: nowrap; }
    .presentation-dashboard__wrapper .presentation-dashboard__controls span {
      margin-left: 8px;
      cursor: pointer;
      position: relative;
      transition: 0.2s all;
      width: 26px;
      height: 26px;
      display: inline-block; }
    .presentation-dashboard__wrapper .presentation-dashboard__controls svg {
      margin-left: 8px;
      cursor: pointer; }
    .presentation-dashboard__wrapper .presentation-dashboard__controls .controls-centered {
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translateX(-50%); }
      .presentation-dashboard__wrapper .presentation-dashboard__controls .controls-centered .controls__scenes-nav-arrow {
        width: 24px;
        height: 24px;
        transform: scaleY(1);
        margin: 0 20px;
        display: inline-block;
        fill: white; }
        .presentation-dashboard__wrapper .presentation-dashboard__controls .controls-centered .controls__scenes-nav-arrow:nth-child(1) {
          transform: rotate(-90deg); }
        .presentation-dashboard__wrapper .presentation-dashboard__controls .controls-centered .controls__scenes-nav-arrow:nth-child(3) {
          transform: rotate(90deg); }
      @media only screen and (max-width: 400px) {
        .presentation-dashboard__wrapper .presentation-dashboard__controls .controls-centered .controls__scenes-nav-arrow {
          margin: 0 15px; } }
  .presentation-dashboard__wrapper .presentation-dashboard__content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .presentation-dashboard__wrapper .presentation-dashboard__content > div {
      box-sizing: border-box; }
  .presentation-dashboard__wrapper--open .presentation-dashboard__controls .controls__scenes-nav-arrow:nth-child(2) {
    transform: scaleY(-1); }

.presentation-dashboard--fullscreen .presentation-dashboard__wrapper, .presentation-dashboard--minimal .presentation-dashboard__wrapper {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  bottom: 0;
  padding: 0;
  color: #adadad;
  margin: 0;
  max-width: none;
  border-radius: 0;
  width: 100%;
  overflow: visible; }

.presentation-dashboard--fullscreen .presentation-dashboard__controls, .presentation-dashboard--minimal .presentation-dashboard__controls {
  color: white;
  flex-direction: row; }
  .presentation-dashboard--fullscreen .presentation-dashboard__controls .controls__scroll-button, .presentation-dashboard--minimal .presentation-dashboard__controls .controls__scroll-button {
    display: none; }

.presentation-dashboard--fullscreen .presentation-dashboard__wrapper .presentation-dashboard__content {
  display: none; }

.presentation-dashboard--fullscreen .presentation-dashboard__wrapper--open {
  top: calc(100% - 51px); }

@media (min-width: 768px) {
  .presentation-dashboard__wrapper {
    width: calc(100vw - 50px); } }

@media (max-width: 675px) {
  .presentation-dashboard__wrapper {
    top: calc(100% - 85px); }
  .presentation-dashboard__header {
    display: block !important; }
  .presentation-dashboard__controls {
    margin-top: 4px !important;
    align-items: center;
    justify-content: space-between; }
    .presentation-dashboard__controls .flex-container {
      margin-right: 0 !important; } }

@media (max-width: 400px) {
  .presentation-dashboard__wrapper {
    top: calc(100% - 116px); } }

.presentation-dashboard__wrapper--open {
  top: auto; }

body.ui-hidden .presentation-dashboard {
  display: none; }
