body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.modal.privatePresentation-modal .modal__window {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transform: none;
  max-height: none;
  border-radius: 2px;
  overflow: hidden;
  padding: 0;
  background: #fff; }
  .modal.privatePresentation-modal .modal__window .modal__body {
    padding: 0; }
  .modal.privatePresentation-modal .modal__window .modal-title {
    background: #2E2E2E;
    padding: 22px;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-align: left;
    margin-top: -1px; }
  .modal.privatePresentation-modal .modal__window .modal-content {
    color: #828282;
    padding: 0 40px;
    min-height: 220px; }
    .modal.privatePresentation-modal .modal__window .modal-content form {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .modal.privatePresentation-modal .modal__window .modal-content form label {
        padding-top: 18px;
        padding-bottom: 10px;
        font-size: 24px;
        color: #BDBDBD; }
      .modal.privatePresentation-modal .modal__window .modal-content form input[type="password"] {
        box-shadow: none;
        font-size: 26px;
        text-align: center;
        color: #2E2E2E;
        border: none;
        border-bottom: 2px solid #bdbdbd;
        max-width: 190px;
        margin: 0 auto 44px; }
        .modal.privatePresentation-modal .modal__window .modal-content form input[type="password"].error {
          border-bottom: 2px solid #bdbdbd; }
    .modal.privatePresentation-modal .modal__window .modal-content .tip {
      margin-bottom: 1rem; }
    .modal.privatePresentation-modal .modal__window .modal-content .error {
      color: #f00;
      margin-bottom: 1rem; }
    .modal.privatePresentation-modal .modal__window .modal-content .success {
      color: #27AE60; }
    .modal.privatePresentation-modal .modal__window .modal-content .input-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 153px; }
      .modal.privatePresentation-modal .modal__window .modal-content .input-wrapper.higher {
        min-height: 220px; }
    .modal.privatePresentation-modal .modal__window .modal-content .cta-button {
      flex-grow: 0;
      background: #ffff00;
      border-radius: 30px;
      padding: 14px 0;
      width: 220px;
      border: none;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;
      margin-bottom: 25px; }
  .modal.privatePresentation-modal .modal__window .modal-secondary-content {
    background: #F2F2F2; }

@media (min-width: 768px) {
  .modal.privatePresentation-modal .modal__window {
    width: 450px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    .modal.privatePresentation-modal .modal__window .modal__body {
      width: 100%; } }

body.ui-hidden .container {
  display: none; }
