.scenes-nav {
  margin: 0;
  width: 100%;
  overflow: hidden;
  position: relative; }
  .scenes-nav .owl-nav {
    pointer-events: none; }
    .scenes-nav .owl-nav .owl-prev, .scenes-nav .owl-nav .owl-next {
      position: absolute;
      bottom: 0;
      text-indent: -2000px;
      height: 133px;
      width: 110px;
      background: url("/static/home/embed/arrow.png") no-repeat 80px center, linear-gradient(to right, transparent 0, rgba(0, 0, 0, 0.3) 110px);
      pointer-events: all;
      cursor: pointer; }
      .scenes-nav .owl-nav .owl-prev.disabled, .scenes-nav .owl-nav .owl-next.disabled {
        display: none; }
    .scenes-nav .owl-nav .owl-prev {
      left: 0;
      transform: scaleX(-1); }
    .scenes-nav .owl-nav .owl-next {
      right: 0; }
  .scenes-nav .scene {
    position: relative;
    display: inline-block;
    width: 170px;
    box-sizing: border-box;
    pointer-events: all;
    cursor: pointer;
    padding: 6px 6px 0 6px; }
    .scenes-nav .scene .scene__background {
      display: block;
      height: 80px;
      opacity: .7;
      transition: all 0.2s;
      border-radius: 4px;
      overflow: hidden; }
    .scenes-nav .scene .scene__name {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      transition: all 0.2s;
      color: #fff;
      opacity: .7;
      padding: 7px 14px 17px 14px; }
      .scenes-nav .scene .scene__name span {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .scenes-nav .scene.scene--selected .scene__background, .scenes-nav .scene:hover .scene__background {
      opacity: 1;
      box-shadow: 0 0 14px rgba(255, 255, 255, 0.4); }
    .scenes-nav .scene.scene--selected .scene__name, .scenes-nav .scene:hover .scene__name {
      opacity: 1; }
