.notifications {
  position: fixed;
  z-index: 11000;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center; }
  .notifications .notification {
    color: #fff;
    background: #008AAE;
    margin: 10px;
    padding: 10px 44px 10px 20px;
    border-radius: 7px;
    display: flex;
    position: relative; }
    .notifications .notification .close {
      position: absolute;
      color: #fff !important;
      height: 20px !important;
      right: 10px;
      cursor: pointer; }
