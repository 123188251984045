.evr-player {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 10; }
  .evr-player.white .evr-ui .evr-loader .loader-progress path {
    stroke: #fff; }
  .evr-player.evr-player--no-overlay .ui-overlay {
    display: none; }
