body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.support-modal .modal__body {
  width: auto;
  padding: 30px;
  display: flex; }

.support-modal form {
  width: 100%; }

.support-modal .message-text-field {
  margin-top: 20px;
  /* Placeholder */ }
  .support-modal .message-text-field hr {
    bottom: 22px !important; }
  .support-modal .message-text-field > div:first-child {
    top: 12px !important;
    bottom: auto !important; }

.support-modal button {
  background: #008B87;
  color: #fff; }

@media (min-width: 768px) {
  .support-modal .modal__body {
    width: 540px; } }
